import React from "react";
import logo from "../images/logo.png"

function Logo() {
  return (
    <div className="logo-wrapper">
        <img src={logo} alt="" />
    </div>
  );
}

export default Logo;