import { useEffect } from "react";
import {
  OwnerRegistrationRequest,
  PageState,
} from "../screens/RegistrationScreen";
import Button from "./Button";
import Input from "./Input";
import { VansInput } from "./VanInput";

export const regexEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+(?:[.][a-zA-Z0-9]+)+$/;
const isValidEmail = (email: string, confirmEmail: string) =>
  regexEmail.test(email) && email.toLowerCase() === confirmEmail.toLowerCase();

export const regexPhoneValidation = /^[0-9\b]{10,11}$/;
const isValidPhoneNumber = (phone: string) =>
  phone.replace(/[^0-9]/g, "").length === 11
    ? true
    : phone.replace(/[^0-9]/g, "").length === 10
    ? true
    : false;

export const regexName = /^[\D\s\b]+$/;
const regexEmptyText = /^([\s]+([\w]?)+)+$/;
export const isValidText = (text: string) => {
  if (regexEmptyText.test(text)) return false;
  if (text.length < 3) return false;
  return true;
};

function isOwnerRegistrationValid(owner: OwnerRegistrationRequest) {
  if (!isValidText(owner.name)) return false;
  if (!isValidPhoneNumber(owner.phoneNumber)) return false;
  if (!isValidEmail(owner.email, owner.confirmEmail)) return false;
  if (owner.vanNames.some((vanName) => !isValidText(vanName))) return false;
  if (owner.role !== "owner") return false;
  if (owner.aware === false) return false;
  if (owner.businessName !== "" && !isValidText(owner.businessName))
    return false;
  if (owner.reCaptchaToken === "") return false;
  return true;
}

function formatPhoneNumber(phoneNumber: string) {
  const clean = phoneNumber.replace(/\D/g, "");
  if (phoneNumber.length === 1 || phoneNumber.length === 2) {
    return clean.replace(/^(\d+)/g, "($1");
  }
  if (phoneNumber.length > 2 && phoneNumber.length < 7) {
    return clean.replace(/^(\d\d)(\d+)$/g, "($1) $2");
  }
  if (phoneNumber.length >= 7 && phoneNumber.length <= 10) {
    return clean.replace(/^(\d\d)(\d{4})(\d+)$/g, "($1) $2-$3");
  }
  if (phoneNumber.length === 11) {
    return clean.replace(/^(\d\d)(\d{5})(\d{4})$/g, "($1) $2-$3");
  }
  return clean;
}

export function Form({
  hidden,
  ownerRegistration,
  pageState,
  onNameChange,
  onPhoneNumberChange,
  onEmailChange,
  onConfirmEmailChange,
  onVanNameChange,
  onVansSizeIncrease,
  onVansSizeDecrease,
  onBusinessNameChange,
  onAwareChange,
  onSubmit,
  setupRecaptcha,
  resetRecaptcha,
}: {
  hidden: boolean;
  ownerRegistration: OwnerRegistrationRequest;
  pageState: PageState;
  onNameChange: (name: string) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  onEmailChange: (email: string) => void;
  onConfirmEmailChange: (email: string) => void;
  onVanNameChange: (vanName: string, index: number) => void;
  onVansSizeIncrease: () => void;
  onVansSizeDecrease: () => void;
  onBusinessNameChange: (businessName: string) => void;
  onAwareChange: () => void;
  onSubmit: () => void;
  setupRecaptcha: (reCaptchaToken: string) => void;
  resetRecaptcha: () => void;
}) {
  useEffect(() => {
    const windowVar: any = window;
    windowVar.setReCaptchaToken = setupRecaptcha;
    windowVar.deleteReCaptchaToken = resetRecaptcha;
  }, [ownerRegistration]);

  return (
    <div className={hidden ? "hidden" : "form-wrapper"}>
      <Input
        label="Nome Completo"
        isInputValid={
          isValidText(ownerRegistration.name) &&
          regexName.test(ownerRegistration.name)
        }
        text={ownerRegistration.name.trimStart()}
        onTextChange={(text) => {
          if (regexName.test(text) || text === "") {
            onNameChange(text);
          }
        }}
      />
      <Input
        label="Telefone"
        isInputValid={regexPhoneValidation.test(ownerRegistration.phoneNumber)}
        text={formatPhoneNumber(ownerRegistration.phoneNumber)}
        onTextChange={(text) => onPhoneNumberChange(text)}
      />
      <Input
        label="E-mail"
        isInputValid={regexEmail.test(ownerRegistration.email)}
        text={ownerRegistration.email}
        onTextChange={(text) => onEmailChange(text.trim())}
      />
      <Input
        label="Confirmar E-mail"
        isInputValid={
          ownerRegistration.email.toLowerCase() ===
          ownerRegistration.confirmEmail.toLowerCase()
        }
        text={ownerRegistration.confirmEmail}
        onTextChange={(text) => onConfirmEmailChange(text.trim())}
      />
      <VansInput
        vanNames={ownerRegistration.vanNames}
        onVanNameChange={onVanNameChange}
        onVansSizeDecrease={onVansSizeDecrease}
        onVansSizeIncrease={onVansSizeIncrease}
      />
      <Input
        label="Nome da empresa"
        isInputValid={
          ownerRegistration.businessName.trim().length > 2 ||
          ownerRegistration.businessName === ""
        }
        text={ownerRegistration.businessName}
        onTextChange={(text) => onBusinessNameChange(text)}
        isRequired={false}
      />
      <div className="form-check">
        <input
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onChange={onAwareChange}
        />
        <label className="form-check-label">
          Estou ciente que <b>NÃO</b> se trata de um serviço para quem procura
          transporte escolar e sim um aplicativo para <b>PROPRIETÁRIOS</b> de
          vans escolares.
        </label>
      </div>
      <div
        className="g-recaptcha"
        data-sitekey={
          window.location.href.indexOf("localhost") !== -1
            ? "6LffHXYdAAAAAAYrZQJVPZL2tPa0hVZM2Czf0Qtq"
            : "6LdbXXMdAAAAAJDDlCMGFCNd4eXP0uBRn-SWw9El"
        }
        data-callback="setReCaptchaToken"
        data-expired-callback="deleteReCaptchaToken"
      />
      <Button
        label={pageState.state === "sending" ? "Enviando..." : "Enviar"}
        disabled={
          !isOwnerRegistrationValid(ownerRegistration) ||
          pageState.state === "sending"
        }
        onButtonClick={onSubmit}
      />

      {pageState.state === "error" && (
        <div className="alert alert-warning text-center">
          {pageState.errorMsg}
        </div>
      )}
    </div>
  );
}
